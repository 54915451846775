import React from 'react';
import ReactGA from "react-ga4";
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import AppRoutes from './appRoutes/appRoutes';

import { CircularProgress } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import enLang from './locales/en.json';
import nlLang from './locales/nl.json';
import frLang from './locales/fr.json';

function App() {

  ReactGA.initialize("G-DGH5V4MLFK");

  const selectedLanguage = localStorage.getItem('selectedLanguage');

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
  });

  i18n.use(initReactI18next).init({
    resources: {
      EN: {
        translation: enLang,
      },
      NL: {
        translation: nlLang,
      },
      FR: {
        translation: frLang,
      },
    },
    lng: selectedLanguage || 'EN',
    fallbackLng: 'EN',
    debug: false,
  });

  return (
    <React.Suspense
      fallback={
        <div className="flex items-center justify-center h-screen m-auto">
          <CircularProgress color="error" />
        </div>
      }
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </QueryClientProvider>
      </LocalizationProvider>
    </React.Suspense>
  );
}

export default App;
